import React from "react"

import LayoutSecondary from "../components/layout-secondary"
import Seo from "../components/seo"
import get from 'lodash/get';
import { graphql } from 'gatsby';
import HeroResources from "../components/heroes/hero-resources"
import {Container, Row, Col} from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-react-intl"
import BlogCard from "../components/misc/blog-card";
import SimpleMailingList from "../components/ctas/simple-mailing-list";

class BlogIndex extends React.Component {
    render() {
        const posts = get(this, 'props.data.allMdx.edges');
        return (
            <LayoutSecondary>
                <Seo
                    title={this.props.intl.formatMessage({ id: "pages.blog.title" })}
                    description={this.props.intl.formatMessage({ id: "pages.blog.description" })}
                    pathname={this.props.location.pathname}
                    ogType={`blog`}
                />
                <HeroResources
                    theme={`secondary`}
                    content={{ title: this.props.intl.formatMessage({ id: "pages.blog.title" })}}
                />
                <div className={`top-main-overflow-wrapper`}>
                    <Container>
                        <Row>
                            {posts.map(({ node }) => {
                                if (this.props.intl.locale === node.frontmatter.lang) {
                                    const item = {
                                        image: node.fields.cover ,
                                        title: node.frontmatter.title,
                                        postInfo: {
                                          date: node.frontmatter.date,
                                          author: node.frontmatter.author,
                                          tags: node.frontmatter.tags
                                        },
                                        text: node.frontmatter.excerpt,
                                        button: {
                                            label: this.props.intl.formatMessage({ id: "shared.readMore" }),
                                            url: node.fields.slug
                                        }
                                    };
                                    return (
                                        <Col key={node.fields.slug} md={4}>
                                            <BlogCard content={item}/>
                                        </Col>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </Row>
                    </Container>
                </div>
                <SimpleMailingList />
            </LayoutSecondary>
        )
    }
}

export default injectIntl(BlogIndex)

export const pageQuery = graphql`
    query BlogPostMonthList($year: Date, $month: String) {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMdx(sort: { fields: [fields___publishDate], order: DESC } 
            limit: 1000 
            filter: { 
                frontmatter: {
                    pageType: { eq: "blog" }
                }
                fields: {
                    published: { eq: true }
                    year: { eq: $year }
                    month: { eq: $month }
                }
            }) {
            edges {
                node {
                    fields {
                        slug
                        publishDate
                        year
                        month
                        day
                        cover
                    }
                    frontmatter {
                        date(formatString: "MMM D YYYY")
                        title
                        lang
                        author
                        excerpt
                        description
                        tags
                    }
                }
            }
        }
    }
`;
